.mx-typo(@size, @height, @weight) {
    font-family : 'SourceSansPro';
    font-weight: @weight;
    font-size: @size;
    line-height: @height;
}

.typo-extralarge {
    .mx-typo(26px, 36px, 400);
}
.typo-large {
    .mx-typo(22px, 30px, 400);
}
.typo-medium {
    .mx-typo(18px, 25px, 600);
}
.typo-medium {
    .mx-typo(16px, 22px, 600);
}

.mx-link-nodecoration {
    &:hover {
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
}

//old
.mx-ssp(@size, @weight) {
    font-family : 'SourceSansPro';
    font-weight: @weight;
    font-size: @size;
}
.mx-textellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}