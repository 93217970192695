@import 'color-variables.less';

/****** Class helpers *******/

// Typography
.text-important                     { color:    @text-important; }
.text-weak                          { color:    @text-weak; }
.text-prompt                        { color:    @text-prompt; }
.text-active                        { color:    @text-active; }
.text-disabled                      { color:    @text-disabled; }
.text-error                         { color:    @error-text; }
.text-success                       { color:    @success-text; }
.text-warning                       { color:    @warning-text; }
.text-primary                       { color:    @digital-blue-base; }
.text-debug                         { color:    @debug-text; }

// Backgrounds
.success-background                 { background-color: @success-background-strong; }
.success-background--light          { background-color: @success-background-light; }
.info-background                    { background-color: @info-background-strong; }
.info-background--light             { background-color: @info-background-light; }
.error-background                   { background-color: @error-background-strong; }
.error-background--light            { background-color: @error-background-light; }
.warning-background                 { background-color: @warning-background-strong; }
.warning-background--light          { background-color: @warning-background-light; }

// Link
.link-normal                        { color:    @link-normal; }
.link-hover                         { color:    @link-hover; }
.link-visited                       { color:    @link-visited }

// Misc
.border                             { border-color:     @border-color; }
.line-hover                         { background-color: @line-hover; }
.line-selected                      { background-color: @line-selected; }
.odd-line                           { background-color: @grey-lighten-8; }
.divider                            { border-bottom:    @grey-lighten-7; }
.disabled                           { color:            @text-disabled; }
.page-background                    { background-color: @white-background; }
.background                         { background-color: @grey-background; }
.chips                              { color:            @grey-lighten-8; }


.universe-color {
    &.home                          { color:    @project-home-color; }
    &.flow, &.recipe                { color:    @flow-color; }
    &.datasets, &.dataset           { color:    @dataset-color; }
    &.streaming-endpoint,
    &.streaming_endpoint            { color:    @streaming-endpoint-color; }
    &.managed-folder,
    &.managed_folder
                                    { color:    @dataset-color; }
    &.analysis                      { color:    @analysis-color; }
    &.labeling_task                 { color:    @labeling-task-color; }

    &.notebooks,
    &.notebook,
    &.web_app,
    &.code-studio,
    &.report
                                    { color:    @notebook-color; }
    &.job, &.jobs, &.scenario       { color:    @job-color; }
    &.dashboard                     { color:    @dashboard-color; }
    &.project                       { color:    @grey-lighten-1; }
    &.column                        { color:    @grey-lighten-1; }
    &.discussion                    { color:    @grey-lighten-1; }
    &.wiki, &.article               { color:    @wiki-color; }
    &.bundle                        { color:    @bundle-color; }
    &.metrics                       { color:    @grey-lighten-1; }
    &.recipe                        { color:    @code-recipe-color; }
    &.recipe-visual                 { color:    @visual-recipe-color; }
    &.recipe-custom                 { color:    @custom-recipe-color; }
    &.recipe-custom-red             { color:    @custom-recipe-color-red; }
    &.recipe-custom-pink            { color:    @custom-recipe-color-pink; }
    &.recipe-custom-purple          { color:    @custom-recipe-color-purple; }
    &.recipe-custom-blue            { color:    @custom-recipe-color-blue; }
    &.recipe-custom-green           { color:    @custom-recipe-color-green; }
    &.recipe-custom-sky             { color:    @custom-recipe-color-sky; }
    &.recipe-custom-yellow          { color:    @custom-recipe-color-yellow; }
    &.recipe-custom-orange          { color:    @custom-recipe-color-orange; }
    &.recipe-custom-brown           { color:    @custom-recipe-color-brown; }
    &.recipe-custom-gray            { color:    @custom-recipe-color-gray; }
    &.recipe-code                   { color:    @code-recipe-color; }
    &.recipe-continuous             { color:    @streaming-endpoint-color; }
    &.recipe-train,
    &.recipe-score,
    &.recipe-clustering,
    &.recipe-evaluation,
    &.recipe-standalone,          // for the timeline
    &.recipe-standalone_evaluation
                                    { color:    @ml-recipe-color; }
    &.insight                       { color:    @dashboard-color; }
    &.model-evaluation-store,
    &.model_evaluation_store        { color:    @model-evaluation-store-color; }
    &.model-comparison,
    &.model_comparison              { color:    @model-comparison-color; }
    &.saved-model,
    &.saved_model
                                    { color:    @saved-model-color; }
    &.lambda-service,
    &.lambda_service                { color:    @teal-base; }
    &.chart                         { color:    @chart-color; }
    &.meaning                       { color:    @grey-lighten-1; }
    &.plugin                        { color:    @grey-lighten-2; }
    &.more                          { color:    @gray-blue-base }
    &.navigation                    { color:    @grey-lighten-3 }
    &.api-deployer                  { color:    @api-deployer-color }
    &.project-deployer              { color:    @project-deployer-color }
    &.app                           { color:    @teal-base; }
}

.universe-background    {
    color:                          @white-base !important;
    background:                     @teal-base;

    &.home                          { background-color: @project-home-color; }
    &.flow, &.recipe                { background-color: @flow-color; }
    &.datasets, &.dataset           { background-color: @dataset-color; }
    &.streaming-endpoint,
    &.streaming_endpoint            { background-color: @streaming-endpoint-color; }
    &.chart                         { background-color: @chart-color; }
    &.managed-folder,
    &.managed_folder                { background-color: @dataset-color; }
    &.analysis                      { background-color: @analysis-color; }
    &.labeling_task                 { background-color: @labeling-task-color; }
    &.notebooks,
    &.notebook,
    &.web_app,
    &.code-studio,
    &.report                        { background-color: @notebook-color; }
    &.job, &.jobs, &.scenario       { background-color: @job-color; }
    &.dashboard, &.insight          { background-color: @dashboard-color; }
    &.discussion, &.metrics         { background-color: @grey-lighten-3; }
    &.wiki, &.article               { background-color: @wiki-color; }
    &.bundle                        { background-color: @bundle-color; }
    &.recipe-visual                 { background-color: @visual-recipe-color; }
    &.recipe-code                   { background-color: @code-recipe-color; }
    &.recipe-continuous             { background-color: @streaming-endpoint-color; }
    &.recipe-train,
    &.recipe-score,
    &.recipe-clustering,
    &.recipe-evaluation,
    &.recipe-standalone_evaluation,
    &.saved-model,
    &.saved_model                   { background-color: @ml-recipe-color; }
    &.model-comparison,
    &.model_comparison,
    &.model-evaluation-store,
    &.model_evaluation_store        { background-color: @ml-recipe-color; }
    &.recipe-custom                 { background-color: @custom-recipe-color; }
    &.recipe-custom-red             { background-color: @custom-recipe-color-red; }
    &.recipe-custom-pink            { background-color: @custom-recipe-color-pink; }
    &.recipe-custom-purple          { background-color: @custom-recipe-color-purple; }
    &.recipe-custom-blue            { background-color: @custom-recipe-color-blue; }
    &.recipe-custom-green           { background-color: @custom-recipe-color-green; }
    &.recipe-custom-sky             { background-color: @custom-recipe-color-sky; }
    &.recipe-custom-yellow          { background-color: @custom-recipe-color-yellow; }
    &.recipe-custom-orange          { background-color: @custom-recipe-color-orange; }
    &.recipe-custom-brown           { background-color: @custom-recipe-color-brown; }
    &.recipe-custom-gray            { background-color: @custom-recipe-color-gray; }
    &.shared-objects {
        background-color: @yellow-base;
        &.object-icon i.icon    {
            color: @grey-base !important;
        }
    }
    &.plugin                        { background-color: @grey-lighten-2; }
    &.admin                         { background-color: @grey-lighten-2; }
    &.catalog                       { background-color: @grey-lighten-2; }
    &.inbox                         { background-color: @grey-lighten-2; }
    &.meanings                      { background-color: @grey-lighten-2; }
    &.api-deployer                  { background-color: @api-deployer-color; }
    &.project-deployer              { background-color: @project-deployer-color; }
    &.profile                       { background-color: @grey-lighten-2; }
    &.alation                       { background-color: @grey-lighten-2; }
    &.feature-store                 { background-color: @grey-lighten-2; }
}

.universe-fill  {
    color:                          @white-base !important;
    &.home                          { fill:     @project-home-color; }
    &.flow,
    &.recipe                        { fill:     @flow-color; }
    &.datasets,
    &.dataset                       { fill:     @dataset-color; }
    &.managed-folder,
    &.managed_folder                { fill:     @dataset-color; }
    &.analysis                      { fill:     @analysis-color; }
    &.notebooks,
    &.notebook                      { fill:     @notebook-color; }
    &.job,
    &.jobs,
    &.scenario                      { fill:     @job-color; }
    &.dashboard                     { fill:     @dashboard-color; }
    &.project                       { fill:     @grey-base; }
    &.column                        { fill:     @cyan-blue-base; }
    &.discussions                   { color:    @bright-gray-base; }
    &.metrics                       { fill:     @grey-lighten-3; }
    &.wiki, &.article               { fill:     @wiki-color; }
    &.recipe                        { fill:     @code-recipe-color; }
    &.recipe-visual                 { fill:     @visual-recipe-color; }
    &.recipe-custom                 { fill:     @custom-recipe-color; }
    &.recipe-custom-red             { fill:     @custom-recipe-color-red; }
    &.recipe-custom-pink            { fill:     @custom-recipe-color-pink; }
    &.recipe-custom-purple          { fill:     @custom-recipe-color-purple; }
    &.recipe-custom-blue            { fill:     @custom-recipe-color-blue; }
    &.recipe-custom-green           { fill:     @custom-recipe-color-green; }
    &.recipe-custom-sky             { fill:     @custom-recipe-color-sky; }
    &.recipe-custom-yellow          { fill:     @custom-recipe-color-yellow; }
    &.recipe-custom-orange          { fill:     @custom-recipe-color-orange; }
    &.recipe-custom-brown           { fill:     @custom-recipe-color-brown; }
    &.recipe-custom-gray            { fill:     @custom-recipe-color-gray; }
    &.recipe-code                   { fill:     @code-recipe-color; }
    &.recipe-train,
    &.recipe-score,
    &.recipe-clustering,
    &.recipe-evaluation,
    &.recipe-standalone_evaluation  { fill:     @ml-recipe-color; }
    &.insight                       { fill:     @dashboard-color; }
    &.saved-model,
    &.saved_model                   { fill:     @saved-model-color; }
    &.model-evaluation-store,
    &.model_evaluation_store        { fill:     @model-evaluation-store-color; }
    &.chart                         { fill:     @chart-color; }
    &                               { fill:     @grey-lighten-3; }
    &.meaning                       { fill:     @cyan-blue-base; }
}

.color-visual-recipe                { color:    @visual-recipe-color; }
.color-code-recipe                  { color:    @code-recipe-color; }
.color-ml-recipe                    { color:    @ml-recipe-color; }
